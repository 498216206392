<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->
							<article class="article-body" id="article-body" rel="image-enlarge">
								<p dir="ltr">
									1.To use <strong>{{ $store.state.brandInfo.appName }}</strong
									>, go to your App Store.&nbsp;<img
										src="../../../assets/images/u6KSHqDlXWZzJpA1LiykEcDmGPinR8hwCA.png?1619227641"
										style="width: 86px"
										class="fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48100096582"
									/>2.Search for <strong dir="ltr">{{ $store.state.brandInfo.appName }}&nbsp;</strong
									><span dir="ltr" style="background-color: ">and download the app.</span><strong dir="ltr"><br /></strong>
								</p>
								<p>
									<img :src="getPic('PlayStore.png')" style="width: 207px" class="fr-fil fr-dib" data-attachment="[object Object]" data-id="48100097057" />
								</p>
								<p dir="ltr">
									3.To login type in your <strong>M3U link&nbsp;</strong>which is found in your trial or subscription email. Type the M3U link in the
									<strong>TOP&nbsp;</strong>line where it says <strong dir="ltr">Enter M3U URL.</strong>
								</p>
								<p dir="ltr">
									<strong
										><img
											src="../../../assets/images/ph-KMH9iE7f2TDhnvKsiHcQaEl2_TGXHUw.png?1619228850"
											style="width: 316px"
											class="fr-fil fr-dib"
											data-attachment="[object Object]"
											data-id="48100097099"
									/></strong>
								</p>
								<p dir="ltr">Example:</p>
								<p>
									<span style="font-size: 16px"
										><a dir="ltr" :href="`${$store.state.brandInfo.tvLink}/api/list/EMAIL/PASSWORD`">
											{{ $store.state.brandInfo.tvLink }}/api/list/<strong>EMAIL</strong>/<strong>PASSWORD</strong></a
										></span
									>
								</p>
								<p><br /></p>
								<p dir="ltr">Bob's account info is:</p>
								<p dir="ltr">
									<span style="color: rgb(41, 105, 176)">EMAIL</span>:
									<a href="/cdn-cgi/l/email-protection#d7b5b8b5e4e497b0bab6bebbf9b4b8ba"
										><span style="color: rgb(0, 0, 0)"
											><span class="__cf_email__" data-cfemail="b6d4d9d48585f6d1dbd7dfda98d5d9db">[email&#160;protected]</span></span
										></a
									>
								</p>
								<p dir="ltr"><span style="color: rgb(41, 105, 176)">PASSWORD</span>: 123bb1</p>
								<p><br /></p>
								<p dir="ltr">The M3U link will look like:</p>
								<p>
									<a dir="ltr" :href="`${$store.state.brandInfo.tvLink}/api/list/EMAIL/PASSWORD`">
										{{ $store.state.brandInfo.tvLink }}/api/list/<span class="__cf_email__" data-cfemail="fd9f929fcecebd9a909c9491d39e9290"
											>[email&#160;protected]</span
										>/123bb1</a
									>
								</p>
								<p dir="ltr">
									<img :src="getPic('M3u.png')" style="width: 295px" class="fr-fil fr-dib" data-attachment="[object Object]" data-id="48100096797" />
								</p>
								<p>
									<strong
										><span style="font-size: 16px"><a :href="`${$store.state.brandInfo.tvLink}/api/list/EMAIL/PASSWORD`" dir="ltr"></a></span></strong
									><span style="font-size: 14px"><a dir="ltr" :href="`${$store.state.brandInfo.tvLink}/api/list/EMAIL/PASSWORD`"></a></span>
								</p>
								<p>
									<span dir="ltr" style="font-size: 14px"
										>4. Leave the bottom line BLANK. &nbsp;Do <strong>NOT</strong> type anything in the bottom line
										<strong>[Enter EPG URL (Optional)]</strong>.</span
									>
								</p>
								<p>
									<span dir="ltr" style="font-size: 14px"><br /></span>
								</p>
								<p>
									<span dir="ltr" style="font-size: 14px"
										>5. Press the blue <span style="color: rgb(41, 105, 176)"><strong>DOWNLOAD</strong></span> button.</span
									>
								</p>
								<p dir="ltr"><br /></p>
								<p><br /></p>
								<p dir="ltr">If the app does not load or you get a spinning circle, force close the app and try again. &nbsp;</p>
								<p dir="ltr">Make sure to double check that the format of your m3u link is entered correctly.&nbsp;</p>
								<p dir="ltr">Passwords are Case-Sensitive.</p>
								<p><br /></p>
								<p><br /></p>
							</article>

							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';

export default {
	name: 'installationInstructionsIPhoneIPad',
	data() {
		return {
			title: this.$route.meta.title
		};
	},
	methods: {
		getPic(picSuffix) {
			const appName = _.lowerCase(this.$store.state.brandInfo.appName).replace(/ /g, '');
			if (!appName) {
				return;
			}

			return require(`@/assets/images/with-logo/${appName}${picSuffix}`);
		}
	}
};
</script>

<style scoped></style>
